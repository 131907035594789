import { call, put, takeLatest, all, fork} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
// import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty} from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Update pipeline API
function updatePipelineLogoApi(params) {
  // console.log(params);
  let details = params.pipelineLogoData;
  let id = params.id;
  // console.log(id, details, "params id");
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.UPDATE_PIPELINE_LOGO}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: details,
  });
}

// Update pipeline action effect
export function* updatePipelineActionEffect(updatePipelineAction) {
  const { payload, resolve, reject } = updatePipelineAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(updatePipelineLogoApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Update pipeline action watcher
export function* updatePipelineActionWatcher() {
  yield takeLatest(actions.UPDATE_PIPLEINE_LOGO, updatePipelineActionEffect);
}

export default function* rootSaga() {
  yield all([fork(updatePipelineActionWatcher)]);
}

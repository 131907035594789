import { call, put, takeLatest, all, fork } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
// import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty} from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// work space API
function WorkSpaceApi(id) {
  // console.log(id, "workspace id");
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.WORK_SPACE}/${"1"}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// workSpace action effect
export function* workSpaceActionEffect(workspaceAction) {
  const { payload, resolve, reject } = workspaceAction;
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(WorkSpaceApi, payload);
    // console.log(data);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.WORK_SPACE_ACTION_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// work space action watcher
export function* workSpaceActionWatcher() {
  yield takeLatest(actions.WORK_SPACE_ACTION, workSpaceActionEffect);
}

export default function* rootSaga() {
  yield all([fork(workSpaceActionWatcher)]);
}
// import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
// import * as actions from "../actions/types";
// import axios from "../../utils/axios";
// import _ from "lodash";
// import * as urls from "../../utils/urls";
// import { isEmpty, setCookie } from "../../utils/common";
// import { toggleBackDrop } from "../actions/commonAction";

// // work space API
// function WorkSpaceApi(id) {
//   console.log(id, "workspace id");
//   return axios.request({
//     method: "get",
//     url: `${process.env.REACT_APP_API_BASE_URL}${urls.WORK_SPACE}/${"1"}`,
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
// }
// // Sign-in action effect
// export function* workSpaceActionEffect(workspaceAction) {
//   const { payload, resolve, reject } = workspaceAction;

//   try {
//     // yield put(toggleBackDrop(true));
//     const { data } = yield call(WorkSpaceApi, payload);
//     console.log(data);
//     // yield put(toggleBackDrop(false));

//     if (!isEmpty(data)) {
//       if (resolve) resolve(data);
//     } else {
//       // yield put(toggleBackDrop(false));
//       reject(data);
//     }
//   } catch (e) {
//     console.log(e);
//     // yield put(toggleBackDrop(false));
//     if (reject) reject(e);
//   }
// }
// // Sign-in action watcher
// export function* workSpaceActionWatcher() {
//   yield takeLatest(actions.WORK_SPACE_ACTION, workSpaceActionEffect);
// }

// export default function* rootSaga() {
//   yield all([fork(workSpaceActionWatcher)]);
// }

import * as actions from "./types";

export const workSpace = () => ({
  type: actions.WORK_SPACE_ACTION,
});

export const workSpaceSuccess = () => ({
  type: actions.WORK_SPACE_ACTION_SUCCESS,
});
// import * as actions from "./types";

// export function workSpace(param, resolve, reject) {
//   return {
//     type: actions.WORK_SPACE_ACTION,
//     payload: param,
//     resolve,
//     reject,
//   };
// }

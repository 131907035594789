import { call, put, takeLatest, all, fork } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
// import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

async function pipelineLogoListApi(params) {
  const { page_size } = params;
  const { page_number } = params;

  return axios.request(
    await {
      method: "get",
      url: `${process.env.REACT_APP_API_BASE_URL}${urls.CUSTOMER_LIST}?page_size=${page_size}&page_number=${page_number}`,
    }
  );
}

export function* logoListActionEffect(pipelineLogoListAction) {
  const { payload, resolve, reject } = pipelineLogoListAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(pipelineLogoListApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.CUSTOMER_ACTION_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}

export function* logoListActionWatcher() {
  yield takeLatest(actions.CUSTOMER_ACTION, logoListActionEffect);
}

export default function* rootSaga() {
  yield all([fork(logoListActionWatcher)]);
}

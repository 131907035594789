import * as actions from "./types";

export function userDetails(param, resolve, reject) {
    return {
        type: actions.USER_DETAILS,
        payload: param,
        resolve,
        reject,
    };
}

export const userRolesAction = (roles) => {
    return {
        type: actions.USER_ROLES,
        payload: roles,
    };
};

export const userAuthDetailsAction = (payload) => {
    return {
        type: actions.USER_AUTH_DETAILS,
        payload,
    };
};

export const resetUserAuthDetailsAction = () => {
    return {
        type: actions.RESET_USER_AUTH_DETAILS,
    };
};

export const setOrganizationLogoAction = (payload) => {
    return {
        type: actions.SET_ORGANIZATION_LOGO,
        payload,
    };
};

export const refreshEmployeeDetailsAction = (payload) => {
    return {
        type: actions.REFRESH_EMPLOYEE_DETAILS,
        payload,
    };
};

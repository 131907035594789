import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

//Single Task Details API
function taskDetailsApi(id) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.TASK_DETAILS}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// Single Task Details effect
export function* taskDetailsActionEffect(taskDetailsAction) {
  const { payload, resolve, reject } = taskDetailsAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(taskDetailsApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
//Single Task Details action watcher
export function* taskDetailsActionWatcher() {
  yield takeLatest(actions.TASK_DETAILS, taskDetailsActionEffect);
}

export default function* rootSaga() {
  yield all([fork(taskDetailsActionWatcher)]);
}

import { call, put, takeLatest, all, fork } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// stage - List API
function StageListApi() {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.STAGE_LIST}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

// stage-List action effect
export function* stageListActionEffect(stageListAction) {
  const { payload, resolve, reject } = stageListAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(StageListApi, payload);
    // console.log(data);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.STAGE_DATA_ACTION_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// stage-List action watcher
export function* stageListActionWatcher() {
  yield takeLatest(actions.STAGE_DATA_ACTION, stageListActionEffect);
}

export default function* rootSaga() {
  yield all([fork(stageListActionWatcher)]);
}

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Route, Switch } from "react-router-dom";
import UnauthorizedPage from "../components/UI/Unauthorized/UnauthorizedPage";
import routes from "../constants/Routes/routes";

const PrivateRoutes = () => {
    const pagePermissions = useSelector((state) => state.auth.permissionKeys.pages);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const publicRoutes = Object.values(routes.publicRoutes).map((each) => each.getUrl());
        if (publicRoutes.includes(location.pathname)) {
            history.push(routes.privatePages.DashboardIndexPage.getUrl());
        }
    }, []);

    return (
        <Switch>
            {Object.values(routes.privatePages).map((each) => {
                return (
                    <Route
                        key={each.key}
                        component={
                            each.dependentPermissionKeys.length === 0 ||
                            each.dependentPermissionKeys.find((key) => pagePermissions[key])
                                ? each.Component
                                : UnauthorizedPage
                        }
                        exact
                        path={each.path}
                    />
                );
            })}
        </Switch>
    );
};

export default PrivateRoutes;

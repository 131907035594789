import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "rsuite/dist/rsuite.min.css";
import "./App.css";
import AppRouter from "./AppRouter.js";
import { LayoutProvider } from "./Context/LayoutContext";
import ErrorFallback from "./components/ErrorFallback.jsx";
import MasterdataWrapper from "./components/MasterdataWrapper.jsx";
import { persistor, store } from "./store/index";

function App() {
    const [disabledTopNav, setDisabledTopNav] = useState(true);
    const [disabledBottomNav, setDisabledBottomNav] = useState(true);

    return (
        <ErrorBoundary fallback={<ErrorFallback />}>
            <Provider store={store}>
                <PersistGate loading={"Loading..."} persistor={persistor}>
                    <LayoutProvider
                        value={{
                            disabledTopNav,
                            setDisabledTopNav,
                            disabledBottomNav,
                            setDisabledBottomNav,
                        }}
                    >
                        <MasterdataWrapper>
                            <AppRouter />
                            <ToastContainer
                                position="bottom-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                        </MasterdataWrapper>
                    </LayoutProvider>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    );
}

export default App;

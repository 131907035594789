import { call, put, takeLatest, all, fork} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
// import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty} from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

//Single user Details API
function userDetailsApi(id) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.USER_DETAILS}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}
// Single user Details effect
export function* userDetailsActionEffect(userDetailsAction) {
  const { payload, resolve, reject } = userDetailsAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(userDetailsApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
//Single Task Details action watcher
export function* userDetailsActionWatcher() {
  yield takeLatest(actions.USER_DETAILS, userDetailsActionEffect);
}

export default function* rootSaga() {
  yield all([fork(userDetailsActionWatcher)]);
}

import * as actions from "../actions/types";
import * as urls from "../../utils/urls";

import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import _ from "lodash";
import axios from "../../utils/axios";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

//leads list API
function pipelineLeadsApi(params) {
  const { id } = params;
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.PIPELINE_LEADS}/${id}?limit=100&offset=1`,
    data: params,
  });
}

// leads list effect
export function* pipelineLeadsActionEffect(pipelineLeadsAction) {
  const { payload, resolve, reject } = pipelineLeadsAction;
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(pipelineLeadsApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.PIPELINE_LEADS_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
      return yield put({
        type: actions.PIPELINE_LEADS_FAILED,
        data,
      });
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
    console.log("faild  called", e);
    return yield put({
      type: actions.PIPELINE_LEADS_FAILED,
    });
  }
}
//leads Details action watcher
export function* pipelineLeadsActionWatcher() {
  yield takeLatest(actions.PIPELINE_LEADS, pipelineLeadsActionEffect);
}

export default function* rootSaga() {
  yield all([fork(pipelineLeadsActionWatcher)]);
}

import { makeStyles, alpha } from "@material-ui/core/styles";
const drawerWidth = 230;

const styles = makeStyles((theme) => ({
  toolBar: {
    display: "flex",
    gridTemplateColumns: "200px auto",
    gridGap: "5px",
    padding: 0,
    cursor: "pointer",
    minHeight: 42,
  },
  compContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "98%",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("sm")]: {
      height: 60,
      width: "100%",
      margin: "auto",
      justifyContent: "space-between",
    },
  },
  leftSection: {
    marginLeft: 0,
  },
  rightSection: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  navLink: {
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: "#0F2972",
    },
    color: "#0F2972",
    marginLeft: "30px",
  },
  menuButton: {
    borderRadius: "2px",
    color: "#0F2972",
    padding: "10px 15px",
    fontFamily: "inherited",
    fontSize: "20px",
  },
  menuButonIcon: {
    height: "28px",
    marginRight: "-6px",
    color: "#0F2972",
  },
  menuButtonImageIcon: {
    height: "25px",
    marginRight: "-3px",
  },
  LeadSelectedImageIcon: {
    height: "20px",
    marginRight: "-3px",
  },
  ProfileSelectedImageIcon: {
    height: "23px",
    marginRight: "-3px",
  },
  title: {
    flexGrow: 1,
  },
  navLinkHome: {
    marginLeft: "0",
  },
  logoButton: {
    padding: "10px 5px",
    "&:hover": {
      background: "transparent !important",
    },
  },
  logo: {
    maxHeight: "50px",
    maxWidth: "60px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "40px",
      maxWidth: "50px",
    },
  },
  typography: {
    fontFamily: "Red Hat Display",
    fontSize: "16px",
    fontWeight: "bold",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileViewRightSection: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginRight: 26,
      marginTop: 2,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  appbarBackground: {
    height: 42,
    background: "#fff",
    zIndex: "999",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 5%)",
    [theme.breakpoints.down("sm")]: {
      height: 50,
      justifyContent: "center",
    },
  },
  taskManagementStyle: {
    fontFamily: "Red Hat Display",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#1e2648",
    display: "flex",
    // marginLeft: 10,
  },

  searchIconStyle: {
    color: "#333333",
    zIndex: 1,
  },
  searchInputStyle: {
    // background: "#E1E1E1",
    border: "1px solid #e1e1e1",
    borderRadius: 4,
    fontSize: 15,
    height: 30,
    width: 420,
    [theme.breakpoints.down("sm")]: {
      width: 100,
    },
  },
  inputLabelStyle: {
    color: "black",
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 16,
    marginTop: "0px",
    marginTop: "15px",
  },
  bootstrapRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "7px 12px",
    width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Red Hat Display",
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  editModalButtonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    marginTop: 20,
    marginBottom: 10,
  },
  modalButtonStyle: {
    padding: "0px 0px 0px 0px",
    margin: " 0px 5px 0px 0px",
    height: "38px",
    width: "18%",
    backgroundColor: "#0A2472",
    color: "white",
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  },
  navTopNoticicationIcon: {
    color: "#fff",
    overflow: "hidden",
    marginLeft: "10px",
    background: "#ff6161",
    height: "32px",
    width: "32px",
    textAlign: "center",
    borderRadius: "20px",
    paddingTop: "3px",
  },
  addPlusIcon: {
    color: "#fff",
    overflow: "hidden",
    marginLeft: "10px",
    background: "#8bccff",
    height: "32px",
    width: "32px",
    textAlign: "center",
    borderRadius: "20px",
    paddingTop: "3px",
  },
}));

export default styles;

import axios from "../../api/axios";
import { GET_ROLE_ACCESS_PERMISSION, USER_PROFILE_ACTION } from "../../utils/urls";
import {
    refreshEmployeeDetailsAction,
    resetUserAuthDetailsAction,
    setOrganizationLogoAction,
    userAuthDetailsAction,
} from "../actions/userDetails";

export const userAuthDetailsDispatcher = (authData) => (dispatch) => {
    dispatch(userAuthDetailsAction(authData));
};

export const resetUserAuthDetailsDispatcher = (dispatch) => {
    dispatch(resetUserAuthDetailsAction());
};

export const setOrganizationLogoDispatcher = (logoUrl) => (dispatch) => {
    dispatch(setOrganizationLogoAction(logoUrl));
};

export const refreshUserPermissionsDispatcher = async (dispatch, getState) => {
    const role_id = getState().auth.employee.role_id;
    axios
        .get(`${process.env.REACT_APP_API_BASE_URL}${GET_ROLE_ACCESS_PERMISSION}/${role_id}`)
        .then((response) => {
            if (response.data && response.data.data) {
                const data = response.data.data;

                dispatch(
                    userAuthDetailsAction({
                        permissionsByModuleWise: data,
                    })
                );
            }
        })
        .catch((error) => {
            console.log({ error });
        });
};

export const refreshEmployeeDetailsDispatcher = (dispatch) => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}${USER_PROFILE_ACTION}`).then((response) => {
        // console.log("response :", response);
        if (response.data) {
            if (response.data.data) {
                const userData = {
                    email: response.data.data.email,
                    firstName: response.data.data.firstName,
                    fullName: response.data.data.fullName,
                    image: response.data.data.image,
                    lastName: response.data.data.lastName,
                    phone: response.data.data.phone,
                    role_id: response.data.data.role_id,
                    Role: response.data.data.Role,
                };

                console.log({ userData });

                dispatch(refreshEmployeeDetailsAction(userData));
            }
        }
    });
};

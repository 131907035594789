import { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginPage from "../../pages/Signin/SigninPage.react";
import { getUuid, replaceVariableWithValueInURL } from "../../utils/common";
import permissionKeys from "../Permissions/permissionKeys";

const RedirectToLogin = () => <Redirect to="/login" />;
const RedirectToDashboard = () => <Redirect to="/dashboard" />;
const PageNotFound = lazy(() => import("../../components/UI/NotFound/PageNotFound"));
const PublicPageNotFound = lazy(() => import("../../components/UI/NotFound/PublicPageNotFound"));
const DashboardIndexPage = lazy(() => import("../../pages/Dashboard/Dashboard"));
const CustomersIndexPage = lazy(() => import("../../pages/Contacts/Contacts.react"));
// const SigninPage = lazy(() => import("../../pages/Signin/SigninPage.react"));
const SignupPage = lazy(() => import("../../pages/Signup/SignupPage.react"));
const RoopyaAIChat = lazy(() => import("../../components/ChatRoom/Chat"));

const VerifyConsentOTP = lazy(() => import("../../pages/ConsentPages/VerifyOTP.react.jsx"));
const ConsentForGST = lazy(() => import("../../pages/ConsentPages/Consent.react.jsx"));
const Thankyou = lazy(() => import("../../pages/ConsentPages/Thankyou.react.jsx"));
const VerifyCustomerEmailPage = lazy(() => import("../../pages/VerifyCustomerEmail/VerifyCustomerEmail"));
const ForgotPasswordPage = lazy(() => import("../../pages/ForgotPassword"));
const CreatePasswordPage = lazy(() => import("../../pages/CreatePassword/CreatePassword.react"));
const SignupEmailPage = lazy(() => import("../../pages/Signup/SignupEmail.react"));
const SignupMobilePage = lazy(() => import("../../pages/Signup/SignupMobile.react"));
const SignupMobileOtpPage = lazy(() => import("../../pages/Signup/SignupMobileotp.react"));
const SignupCongratsPage = lazy(() => import("../../pages/Signup/SignupCongrats.react"));
const ForgetPasswordPage = lazy(() => import("../../pages/ForgetPassword/ForgetPassword.react"));
const ForgetPasswordOtpPage = lazy(() => import("../../pages/ForgetPassword/ForgetEmailotp.react"));
const ForgetPasswordFieldPage = lazy(() => import("../../pages/ForgetPassword/ForgetPassfield.react"));
const PartnersTablePage = lazy(() => import("../../pages/Partners/PartnersTable.react"));
const SourceNameTablePage = lazy(() => import("../../pages/SourceName/SourceNameTable.react"));
const VerifyEmailPage = lazy(() => import("../../pages/VerifyEmail"));
const MobileOTPVerificationPage = lazy(() => import("../../pages/Register/MobileOTPVerification"));
const TypeOnePage = lazy(() => import("../../company_pages/pipeline_journey/type_one/type_one.react"));
const LeadsSettingsFormPage = lazy(() => import("../../components/Leads/LeadsSettingsForm"));
const CRIFDetailsPage = lazy(() => import("../../components/CustomerDetails/ScoreDetailsPage"));
const RepaymentSchedule = lazy(() =>
    import("../../components/LeadDetails/TabsDetails/Tabs/Summary/RepaymentSchedule/RepaymentSchedule.jsx")
);
const RepaymentHistoryPage = lazy(() => import("../../components/CustomerDetails/RepaymentHistory"));
const CustomerNewDetailsPage = lazy(() => import("../../components/CustomerDetails/CustomerNewDetailsPage"));
const AddTaskPage = lazy(() => import("../../pages/Task/AddTask"));
const LeadsDetailsNewPage = lazy(() => import("../../pages/Leads/LeadsNewDetails.react"));
const LeadDetailsPage = lazy(() => import("../../pages/LeadDetails/LeadDetails"));
const CustomerDetailsPage = lazy(() => import("../../pages/CustomerDetails/CustomerDetails"));
const CamReportPage = lazy(() => import("../../components/CamReport/Home/Home"));
const UserDetailsProfilePage = lazy(() => import("../../components/UserDetailsProfile"));
const PipelineDetailsPage = lazy(() => import("../../pages/PipelineDetails/PipelineDetails.react"));
const ApplicationDetailsPage = lazy(() => import("../../pages/ApplicationDetails/ApplicationDetails.react"));
const LmsystemPage = lazy(() => import("../../pages/Lmsystem/Lmsystem.react"));
const LosystemPage = lazy(() => import("../../pages/Losystem/Losystem.react"));
const ApplicationPage = lazy(() => import("../../pages/Application/Application.react"));
const UserProfilePage = lazy(() => import("../../pages/UserProfile/UserProfile.react"));
const ContactsPage = lazy(() => import("../../pages/Contacts/Contacts.react"));
const AppsIndexPage = lazy(() => import("../../components/Marketplace/Marketplace"));
const MasterIndexPage = lazy(() => import("../../components/Master/Master.jsx"));
const MyOrganizationPage = lazy(() => import("../../pages/MyOrganization/index.jsx"))
const RepaymentScheme = lazy(() => import("../../components/Master/RepaymentScheme.jsx"));

const UsersPage = lazy(() => import("../../pages/Users"));
const AddPipelineFormPage = lazy(() => import("../../pages/PipelineDashboard/AddPipelineForm"));
const CustomBreBuilderPage = lazy(() => import("../../components/POC/BRE/CustomBreBuilder/CustomBreBuilder"));
const WorkFlowListPage = lazy(() => import("../../pages/WorkFlow/WorkFlowList"));
const WorkFlowDetailsPage = lazy(() => import("../../pages/WorkFlow/WorkFlowDetails"));
const ApplicationsstagesdetailsPage = lazy(() => import("../../components/Applicationsstagesdetails"));
const SettingsIndexPage = lazy(() => import("../../pages/Settings/SettingsNewDesign.react"));
const PipelineFormCreatorPage = lazy(() => import("../../pages/PipelineDashboard/PipelineFormCreator.react"));
const PartnerIndexPage = lazy(() => import("../../pages/Partners/Partners.react"));

const BranchIndexPage = lazy(() => import("../../pages/Branches/branchPage.react.jsx"));
const BranchDetailsPage = lazy(() => import("../../pages/Branches/BranchDetails.react.jsx"));
const BranchSettingsPage = lazy(() => import("../../pages/Branches/settings.react.jsx"));

const PartnerDetailsPage = lazy(() => import("../../pages/Partners/PartnerDetails.react"));
const PartnerPipelineDetailsPage = lazy(() =>
    import("../../pages/PipelineDetails/PartnerModule_PipelineDetails.react")
);
const PipelineSettingsPage = lazy(() => import("../../pages/PipelineDetails/PipelineSettings/PipelineSettings.react"));
const GenerateSanctionLetterPage = lazy(() => import("../../pages/GenerateSanctionLetter/GenerateSanctionLetter"));
const GenerateAgreementPage = lazy(() => import("../../pages/GenerateLoanOfferAgreement/GenerateLoanOfferAgreement"));
const BusinessListPage = lazy(() => import("../../pages/BusinessList/BusinessList.react"));
const BusinessDetailsPage = lazy(() => import("../../pages/BusinessDetails/BusinessDetails"));
const BusinessReportsPage = lazy(() => import("../../components/BusinessList/Reports"));
const BusinessInfromationReportPage = lazy(() => import("../../components/BusinessInformationReport/index.jsx"));
const CreditmemoPage = lazy(() => import("../../pages/Creditmemo/Partners.react"));
const BreIndexPage = lazy(() => import("../../pages/BusinessRules/BusinessRules"));
const ClientsPage = lazy(() => import("../../components/Clients/Clients"));
const RoopyaNetworkIndexPage = lazy(() => import("../../components/BankDetails/BankDetails"));
const UsersLoginPage = lazy(() => import("../../pages/UsersLogin"));
const CreateLeadPage = lazy(() => import("../../components/ContactsList/CreateLeads"));
const BulkSmsSendPage = lazy(() => import("../../components/ContactsList/BulkSmsSend"));
const LeadWiseBulkSmsSendPage = lazy(() => import("../../components/ContactsList/BulkSmsSend/LeadListBulkSMSSend.jsx"));
const BulkSmsSendIndividualPage = lazy(() => import("../../components/SmsSendIndividual"));
const ReportsPage = lazy(() => import("../../components/ContactsList/Reports"));
const PipelineReportsPage = lazy(() => import("../../pages/PipelineDashboard/Reports"));
const ContactAPIPage = lazy(() => import("../../components/ContactsList/ContactAPIs"));
const LeadsDashboardPage = lazy(() => import("../../pages/LeadsDashboard/LeadsDashboard.react"));
const PartnerLeadsDashboardPage = lazy(() => import("../../pages/LeadsDashboard/PartnerModule_LeadsDashboard.react"));
const PipelineIndexPage = lazy(() => import("../../pages/PipelineDashboard/PiplineDashboard.react"));
const TaskListPage = lazy(() => import("../../pages/Task/TaskListPage.react"));
const MyProfile = lazy(() => import("../../components/Profile/MyProfile"));
const CreateProfile = lazy(() => import("../../components/Profile/CreateProfile.jsx"));

const generateRoutes = (Component, url = "", dependentPermissionKeys = [], props = {}) => {
    const getUrl = (values = []) => {
        const cleanUrl = url.includes("/:") ? url.split("/:")[0] : url;
        return values.length === 0 ? cleanUrl : replaceVariableWithValueInURL(url, values);
    };

    return {
        Component,
        dependentPermissionKeys,
        getUrl,
        path: url,
        key: getUuid(),
        props,
    };
};

/**
 * These routes are common for both Public and Private routes
 */
const commonRoutes = {
    UsersLoginPage: generateRoutes(UsersLoginPage, "/users/createpassword", []),
};

const publicRoutes = {
    RootPage: generateRoutes(RedirectToLogin, "/", []),
    SigninPage: generateRoutes(LoginPage, "/login", []),
    SignupPage: generateRoutes(SignupPage, "/register", []),

    VerifyCustomerEmailPage: generateRoutes(VerifyCustomerEmailPage, "/verifyCustomerEmail", []),
    ForgotPasswordPage: generateRoutes(ForgotPasswordPage, "/forgot_password", []),
    CreatePasswordPage: generateRoutes(CreatePasswordPage, "/password/create", []),
    SignupCongratsPage: generateRoutes(SignupCongratsPage, "/signup_success", []),
    SignupEmailPage: generateRoutes(SignupEmailPage, "/signup_email", []),
    SignupMobilePage: generateRoutes(SignupMobilePage, "/signup_mobile", []),
    SignupMobileOtpPage: generateRoutes(SignupMobileOtpPage, "/signup_mobile_otp", []),
    ForgetPasswordPage: generateRoutes(ForgetPasswordPage, "/forget_password", []),
    ForgetPasswordOtpPage: generateRoutes(ForgetPasswordOtpPage, "/forget_password_otp", []),
    ForgetPasswordFieldPage: generateRoutes(ForgetPasswordFieldPage, "/new_password", []),
    PartnersTablePage: generateRoutes(PartnersTablePage, "/partners_table", []),
    SourceNameTablePage: generateRoutes(SourceNameTablePage, "/source_table", []),
    VerifyEmailPage: generateRoutes(VerifyEmailPage, "/verify/email", []),
    MobileOTPVerificationPage: generateRoutes(MobileOTPVerificationPage, "/mobile/otp", []),
    ConsentForGST: generateRoutes(ConsentForGST, "/consent"),
    Thankyou: generateRoutes(Thankyou, "/thankyou"),
    VerifyConsentOTP: generateRoutes(VerifyConsentOTP, "/verifyOTP"),
    TypeOnePage: generateRoutes(TypeOnePage, "/companylist", []),

    ...commonRoutes,
    // keep this at bottom
    PageNotFound: generateRoutes(RedirectToLogin, "*", []),
};

const privatePages = {
    RootPage: generateRoutes(RedirectToDashboard, "/", []),
    DashboardIndexPage: generateRoutes(DashboardIndexPage, "/dashboard", [permissionKeys.pages.DASHBOARD_INDEX_PAGE]),
    CustomersIndexPage: generateRoutes(CustomersIndexPage, "/contacts", [permissionKeys.pages.CUSTOMERS_INDEX_PAGE]),
    BusinessListPage: generateRoutes(BusinessListPage, "/business_list", [permissionKeys.pages.BUSINESS_INDEX_PAGE]),
    RoopyaAIChat: generateRoutes(RoopyaAIChat, `/business_detail/:bname/:bid/chat`, [
        permissionKeys.pages.BUSINESS_INDEX_PAGE,
    ]),

    BusinessReportsPage: generateRoutes(BusinessReportsPage, "/business_list/reports", [
        permissionKeys.pages.BI_REPORTS_PAGE,
    ]),
    PipelineIndexPage: generateRoutes(PipelineIndexPage, "/pipelines", [permissionKeys.pages.CRM_INDEX_PAGE]),
    RoopyaNetworkIndexPage: generateRoutes(RoopyaNetworkIndexPage, "/bank_details_page", [
        permissionKeys.pages.ROOPYA_NETWORK_INDEX_PAGE,
    ]),
    BreIndexPage: generateRoutes(BreIndexPage, "/business_rules", [permissionKeys.pages.BRE_INDEX_PAGE]),
    PartnerIndexPage: generateRoutes(PartnerIndexPage, "/partners", [permissionKeys.pages.PARTNERS_INDEX_PAGE]),
    BranchDetailsPage: generateRoutes(BranchDetailsPage, "/branches/details/:id", []),
    BranchIndexPage: generateRoutes(BranchIndexPage, "/branches", []),
    BranchSettingsPage: generateRoutes(BranchSettingsPage, "/branches/settings", []),

    SettingsIndexPage: generateRoutes(SettingsIndexPage, "/settings", [permissionKeys.pages.SETTINGS_INDEX_PAGE]),
    AppsIndexPage: generateRoutes(AppsIndexPage, "/apps", [permissionKeys.pages.APPS_INDEX_PAGE]),
    MasterIndexPage: generateRoutes(MasterIndexPage, "/master", []),
    MyOrganizationPage: generateRoutes(MyOrganizationPage, "/my_organization", []),
    RepaymentScheme: generateRoutes(RepaymentScheme, "/master/repayment-scheme", []),

    CRIFDetailsPage: generateRoutes(CRIFDetailsPage, "/crif", [permissionKeys.pages.CUSTOMER_CRIF_DETAILS_PAGE]),
    LeadDetailsPage: generateRoutes(LeadDetailsPage, "/leaddetails", [permissionKeys.pages.LEAD_DETAILS_PAGE]),
    CustomerDetailsPage: generateRoutes(CustomerDetailsPage, "/customerDetails", [
        permissionKeys.pages.CUSTOMER_DETAILS_PAGE,
    ]),
    CamReportPage: generateRoutes(CamReportPage, "/camReport", []),
    BusinessInformationReportPage: generateRoutes(BusinessInfromationReportPage, "/BIReport/:id", []),
    PipelineDetailsPage: generateRoutes(PipelineDetailsPage, "/pipeline/details", [
        permissionKeys.pages.PIPELINE_DETAILS_PAGE,
    ]),
    PipelineReportsPage: generateRoutes(PipelineReportsPage, "/pipelines/reports", [
        permissionKeys.pages.BI_REPORTS_PAGE,
    ]),
    AddPipelineFormPage: generateRoutes(AddPipelineFormPage, "/addPipeline", [permissionKeys.pages.ADD_PIPELINE_PAGE]),
    CustomBreBuilderPage: generateRoutes(CustomBreBuilderPage, "/business_rules_details", [
        permissionKeys.pages.BRE_CREATE_PAGE,
    ]),
    CustomBreBuilderEditPage: generateRoutes(CustomBreBuilderPage, "/business_rules_edit_details/:id", [
        permissionKeys.pages.BRE_EDIT_PAGE,
    ]),
    PartnerDetailsPage: generateRoutes(PartnerDetailsPage, "/partners/:id", [
        permissionKeys.pages.PARTNER_DETAILS_PAGE,
    ]),

    PartnerPipelineDetailsPage: generateRoutes(
        PartnerPipelineDetailsPage,
        "/partners/:id/:partnerName/pipeline/:pipelineName/:pipelineId/sourcelink",
        [permissionKeys.pages.PARTNER_PIPELINE_SOURCE_LINK_PAGE]
    ),
    GenerateSanctionLetterPage: generateRoutes(GenerateSanctionLetterPage, "/generateSanctionLetter", [
        permissionKeys.pages.GENERATE_SANCTION_LETTER_PAGE,
    ]),
    GenerateAgreementPage: generateRoutes(GenerateAgreementPage, "/generateLoanOfferAgreement", [
        permissionKeys.pages.LOAN_OFFER_AGREEMENT_PAGE,
    ]),
    BusinessDetailsPage: generateRoutes(BusinessDetailsPage, "/businessDetails", [
        permissionKeys.pages.BUSINESS_DETAILS_PAGE,
    ]),
    CreateLeadPage: generateRoutes(CreateLeadPage, "/create_lead", [permissionKeys.pages.CREATE_LEAD_PAGE]),
    BulkSmsSendPage: generateRoutes(BulkSmsSendPage, "/bulk_sms_send", [permissionKeys.pages.BULK_SMS_SENDING_PAGE]),
    LeadWiseBulkSmsSendPage: generateRoutes(LeadWiseBulkSmsSendPage, "/lead_wise_bulk_sms_send", [
        permissionKeys.pages.BULK_SMS_SENDING_PAGE,
    ]),
    BulkSmsSendIndividualPage: generateRoutes(BulkSmsSendIndividualPage, "/sms_send_to_customer", [
        permissionKeys.pages.SEND_SMS_TO_CUSTOMER_PAGE,
    ]),
    ReportsPage: generateRoutes(ReportsPage, "/contact/reports", [permissionKeys.pages.BI_REPORTS_PAGE]),
    ContactAPIPage: generateRoutes(ContactAPIPage, "/contact_api", [permissionKeys.pages.CONTACT_API_PAGE]),
    LeadsDashboardPage: generateRoutes(LeadsDashboardPage, "/pipeline/leads", [
        permissionKeys.pages.PIPELINE_LEADS_PAGE,
    ]),
    RepaymentSchedule: generateRoutes(RepaymentSchedule, "/repaymentHistory", []),
    PartnerLeadsDashboardPage: generateRoutes(PartnerLeadsDashboardPage, "/partners/pipeline/leads", [
        permissionKeys.pages.PARTNER_PIPELINE_LEADS_PAGE,
    ]),

    MyProfile: generateRoutes(MyProfile, "/myProfile", []),

    CreateProfile: generateRoutes(CreateProfile, "/createProfile", []),

    /**
        // ========================================================================================
        // DISABLED ROUTES starts
        // Do not add/remove any routes from this block!
        // ========================================================================================
        LeadsSettingsFormPage: generateRoutes(LeadsSettingsFormPage, "/leadpullcriteria", []),
        RepaymentHistoryPage: generateRoutes(RepaymentHistoryPage, "/repaymentHistory", []),
        CustomerNewDetailsPage: generateRoutes(CustomerNewDetailsPage, "/customer_details_page", []),
        AddTaskPage: generateRoutes(AddTaskPage, "/addtask"),
        LeadsDetailsNewPage: generateRoutes(LeadsDetailsNewPage, "/leadsdetails", []),
        UserDetailsProfilePage: generateRoutes(UserDetailsProfilePage, "/profile/details", []),
        ApplicationDetailsPage: generateRoutes(ApplicationDetailsPage, "/applicationdetails", []),
        LmsystemPage: generateRoutes(LmsystemPage, "/lmsystem", []),
        LosystemPage: generateRoutes(LosystemPage, "/losystem", []),
        ApplicationPage: generateRoutes(ApplicationPage, "/application", []),
        UserProfilePage: generateRoutes(UserProfilePage, "/profile", []),
        UsersPage: generateRoutes(UsersPage, "/users", []),
        WorkFlowListPage: generateRoutes(WorkFlowListPage, "/wok_flow_List", []),
        WorkFlowDetailsPage: generateRoutes(WorkFlowDetailsPage, "/wok_flow_Details", []),
        ApplicationsstagesdetailsPage: generateRoutes(ApplicationsstagesdetailsPage, "/applicationstage", []),
        PipelineFormCreatorPage: generateRoutes(PipelineFormCreatorPage, "/pipeline/form_creator", []),
        PipelineSettingsPage: generateRoutes(PipelineSettingsPage, "/pipeline_settings", []),
        CreditmemoPage: generateRoutes(CreditmemoPage, "/credit_account_memorandam_report", []),
        ClientsPage: generateRoutes(ClientsPage, "/clients", []),
        TaskListPage: generateRoutes(TaskListPage, "/taskpage", []),
        // ========================================================================================
        // DISABLED ROUTES ends
        // ========================================================================================
    */

    ...commonRoutes,
    // keep this at bottom
    PageNotFound: generateRoutes(PageNotFound, "*", []),
};

const routes = {
    publicRoutes,
    privatePages,
};

export default routes;

import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 230;

const styles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    background: "#1E2648",
    filter: "none !important",
  },
  drawerOpen: {
    zIndex: "800",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "#0A2472",
    overflow: "visible",
  },
  drawerClose: {
    zIndex: "800",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    background: "#474D66",

    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8),
      overflow: "visible",
      background: "#0A2472",
    },
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
    minHeight: theme.spacing(9),
    background: "#1E2648",
  },
  navLink: {
    borderRadius: "6px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",

    "&:hover,&:focus,&:visited": {
      color: "#111",
      backgroundColor: "rgba(139,167,252,0.2)",
    },
  },

  logo: {
    width: "76px",
  },
  navIcons: {
    height: 15,
    width: 15,
  },
  quoteIcon: {
    color: "#4A4A4A",
    fontSize: "30px",
    padding: "7px",
    border: "1px solid #4A4A4A",
    borderRadius: "50%",
  },
  listStyle: {
    marginTop: 40,
    background: "#0A2472",
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(6) + 2,
      marginTop: 60,
    },
  },
  listText: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "20px",
    overflow: "hidden",
    whiteSpace: "break-spaces",
  },
  visibility: {
    visibility: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  tooltipSize: {
    fontSize: 16,
  },
  titlestyle: {
    color: "#fff",
    fontSize: 15,
    lineHeight: "26px",
    fontWeight: 400,
  },
  sidebarNavIcon: {
    width: 23,
    height: 23,
    color: "#fff",
    opacity: 1,
  },
  arrowIconStyle: {
    width: "30px !important;",
    height: "50px !important;",
    padding: "4px",
    position: "absolute",
    right: "-10px",
    bottom: "40%",
    cursor: "pointer",
    backgroundColor: "#0A2472",
    color: "#fff",
    borderRadius: "6px",
  },
  rotatearrowIconStyle: {
    width: "30px !important;",
    height: "50px !important;",
    padding: "4px",
    position: "absolute",
    right: "-10px",
    bottom: "40%",
    cursor: "pointer",
    backgroundColor: "#0A2472",
    color: "#fff",
    borderRadius: "6px",
    transform: "rotate(180deg)",
  },
  // rotatearrowIconStyle: {
  //   background: "#fff",
  //   borderRadius: "20px",
  //   width: "30px !important;",
  //   height: "30px !important;",
  //   fontSize: "16px",
  //   color: "#1e2648",
  //   boxShadow:
  //     "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
  //   padding: "4px",
  //   position: "absolute",
  //   right: "-16px",
  //   cursor: "pointer",
  //   transition: "o.3s",
  //   bottom: "-50px",
  //   transform: "rotate(180deg)",
  // },
}));

export default styles;

import {
  call,
  put,
  takeLatest,
  all,
  fork,
  select,
  takeEvery,
} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

function activeBankListLeadsApi() {
  const domain = getCookie("domain");
  // console.log(domain);
  const access_token = getCookie("token");
  return axios.request({
    method: "get",
    // url: `https://api.yoindi.com/api/v1/pipeline/leads/logs`,
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.ACTIVE_BANK_LIST_LEADS_URL}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

export function* activeBankListLeadsActionEffect(activeBankListLeadsAction) {
  const { resolve, reject } = activeBankListLeadsAction;
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(activeBankListLeadsApi);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      return yield put({
        type: actions.ACTIVE_BANK_LIST_LEADS_ACTION_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}

export function* activeBankListLeadsActionWatcher() {
  yield takeLatest(
    actions.ACTIVE_BANK_LIST_LEADS_ACTION,
    activeBankListLeadsActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(activeBankListLeadsActionWatcher)]);
}

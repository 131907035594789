import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// changePassword API
function changePasswordApi(params) {
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.CHANGE_PASSWORD}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: params,
  });
}

// changePassword action effect
export function* changePasswordActionEffect(loginAction) {
  const { payload, resolve, reject } = loginAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(changePasswordApi, payload);
    yield put(toggleBackDrop(false));
    // console.log("data", data.token);
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}

// changePassword action watcher
export function* changePasswordActionWatcher() {
  yield takeLatest(actions.CHANGE_PASSWORD, changePasswordActionEffect);
}

export default function* rootSaga() {
  yield all([fork(changePasswordActionWatcher)]);
}

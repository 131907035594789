import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as headersKeyFile from "../sagas/headersCommonKeyData";
// Add member API
function autoLoginCronRefreshApi(params) {
  console.log("autoLoginListApi", params);
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.AUTO_LOGIN_CRON_REFRESH}`,
    // headers: {
    //     "Content-Type": "application/json",
    // },
  });
}

// Add member action effect
export function* autoLoginCronRefreshActionEffect(autoLoginRefresh) {
  const { payload, resolve, reject } = autoLoginRefresh;
  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(autoLoginCronRefreshApi, payload);
    // yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
      console.log("autoLoginCronRefresh Saga", data);
      return yield put({
        type: actions.AUTO_LOGIN_REFRESH_SUCCESS,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
      return yield put({
        type: actions.AUTO_LOGIN_REFRESH_ERROR,
        data,
      });
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}

// Sign-in action watcher
export function* autoLoginCronRefreshActionWatcher() {
  yield takeLatest(
    actions.AUTO_LOGIN_REFRESH,
    autoLoginCronRefreshActionEffect
  );
}

export default function* rootSaga() {
  yield all([fork(autoLoginCronRefreshActionWatcher)]);
}

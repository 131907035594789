import * as urls from "../../utils/urls";
import * as actions from "../actions/types";

import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { isEmpty, setCookie } from "../../utils/common";

import axios from "../../utils/axios";
import { toggleBackDrop } from "../actions/commonAction";

// logout API
export function LogoutApi() {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.LOGOUT_ACTION}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
  });
}

//logout action effect
export function* logoutActionEffect(logoutAction) {
  const { payload, resolve, reject } = logoutAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(LogoutApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      setCookie("token", "", -1);
      setCookie("domain", "", -1);
      // setCookie("cron", "", -1)
      if (resolve) resolve(data);
      return yield put({
        type: actions.LOGOUT_ACTION,
        data,
      });
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Logout action watcher
export function* logoutActionWatcher() {
  yield takeLatest(actions.LOGOUT_ACTION, logoutActionEffect);
}

export default function* rootSaga() {
  yield all([fork(logoutActionWatcher)]);
}

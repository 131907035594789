// * Styles
import style from "./Layout.module.css";
import useStyles from "./Layout.style";
// * React Hooks
import { useContext, useState } from "react";
// * MUI Components
import { Hidden } from "@mui/material";
// * NPM Packages
import { useDispatch, useSelector } from "react-redux";
// * Components
import Snackbar from "../../components/Snackbar/Snackbar";
import BottomNav from "./BottomNav";
import TopNav from "./Navbar";
import SideNav from "./SideNav";
// * Contexts
import LayoutContext from "../../Context/LayoutContext";
// * Helpers

import { isEmpty } from "../../../src/utils/common";
import { updateAlertMsg } from "../../store/actions/commonAction";

const Layout = (props) => {
  const dispatch = useDispatch();
  const [drawerWidth, setDrawerWidth] = useState(false);
  const layoutContext = useContext(LayoutContext);
  const classes = useStyles();
  const token = useSelector((state) => state.auth.token);

  const { alertMsg = {} } = useSelector((state) => state.commonReducer);

  const ondrowerWidthChange = () => {
    setDrawerWidth(!drawerWidth);
  };

  return (
    <>
      <Snackbar
        message={alertMsg.message}
        open={!isEmpty(alertMsg)}
        messageType={alertMsg.messageType}
        closeNotification={() => dispatch(updateAlertMsg({}))}
        action={alertMsg.action}
      />

      {token && !layoutContext.disabledTopNav && (
        <Hidden>
          <div className={classes.mobileViewSideNav}>
            {/* <div className={style.mobileViewSideNav}> */}
            <SideNav
              ondrowerWidthChange={ondrowerWidthChange}
              setDrawerWidth={setDrawerWidth}
            />
          </div>
          <TopNav />
        </Hidden>
      )}
      <div
        // className={
        //   layoutContext.disabledTopNav
        //     ? classes.container11
        //     : drawerWidth
        //     ? classes.containerRight11
        //     : classes.containerRightFull11
        // }
        className={
          layoutContext.disabledTopNav
            ? style.container11
            : drawerWidth
              ? style.containerRight11
              : token ? classes.containerRightFull11 : undefined
        }
      >
        <div className={classes.mainPaper}>{props.children}</div>
      </div>
      {!layoutContext.disabledBottomNav && (
        <Hidden smUp>
          <BottomNav />
        </Hidden>
      )}
    </>
  );
};

export default Layout;

import axios from "../../axios";

export const getPermissionsByRoleId = async (role_id) => {
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/access_level/get-permissions-by-role-id`, {
        role_id,
    });
    return res?.data;
};

export const assignPermissionsToRole = async (data) => {
    const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/access_level/assign-permission-by-role-id`,
        data
    );
    return res?.data;
};

export const getAllRoles = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/access_level/get-roles`);
    return res?.data;
};

export const createRole = async (data) => {
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/access_level/create-role`, data);
    return res?.data;
};

export const updateRole = async (data) => {
    const res = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/access_level/update-roles`, data);
    return res?.data;
};

export const getUsersByRoleId = async (data) => {
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/get-users-by-role`, data);
    return res?.data;
};

export const getRolesWhichHasParentRole = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/access_level/get-roles-which-has-parent-role`);
    return res?.data;
};

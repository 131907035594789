import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import axios from "../../utils/axios";
import { isEmpty } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";
import * as actions from "../actions/types";

// add role API
function addRoleApi(params) {
    return axios.request({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/access_level/create-role`,
        // headers: {
        //   "Content-Type": "application/json",
        // },
        data: params,
    });
}

// Add role action effect
export function* addRoleActionEffect(loginAction) {
    const { payload, resolve, reject } = loginAction;

    try {
        yield put(toggleBackDrop(true));
        const { data } = yield call(addRoleApi, payload);
        yield put(toggleBackDrop(false));

        if (!isEmpty(data)) {
            if (resolve) resolve(data);
        } else {
            yield put(toggleBackDrop(false));
            reject(data);
        }
    } catch (e) {
        yield put(toggleBackDrop(false));
        if (reject) reject(e);
    }
}
// Add role action watcher
export function* addRoleActionWatcher() {
    yield takeLatest(actions.ADD_ROLE, addRoleActionEffect);
}

export default function* rootSaga() {
    yield all([fork(addRoleActionWatcher)]);
}

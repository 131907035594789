import { call, put, takeLatest, all, fork} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
// import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Update task API
function updateTaskApi(params) {
  // console.log(params);
  let id = params.task_id;
  // console.log(id, "params id");
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.UPDATE_TASK}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: params,
  });
}

// Update task action effect
export function* updateTaskActionEffect(updateTaskAction) {
  const { payload, resolve, reject } = updateTaskAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(updateTaskApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Update task action watcher
export function* updateTaskActionWatcher() {
  yield takeLatest(actions.UPDATE_TASK, updateTaskActionEffect);
}

export default function* rootSaga() {
  yield all([fork(updateTaskActionWatcher)]);
}

import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducers } from "./reducers/index";
import rootSaga from "./sagas/index";
// import freeze from "redux-freeze";
import { createHashHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

let middlewares = [];

const persistedReducer = persistReducer(
  {
    key: "Roopya",
    storage,
  },
  rootReducers
);

//const history = createBrowserHistory();
const history = createHashHistory();

middlewares.push(routerMiddleware(history));

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsDenylist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares, thunk)
  // other store enhancers if any
);

const store = createStore(persistedReducer, enhancer);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { history, store };

import { AiOutlineLogout } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { RiLockPasswordLine } from "react-icons/ri";
import * as routeTo from "../../utils/routeConstants";
import style from "./Navbar.module.css";
import useStyles from "./TopNav.style";
// * MUI Components
import {
    AppBar,
    Avatar,
    Box,
    Button,
    CssBaseline,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    FormHelperText,
    Grid,
    InputBase,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    TextField,
} from "@mui/material";

// * Icons
import Toolbar from "@material-ui/core/Toolbar";
// import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";

// * Images
import roopyaLogoNew from "../../assets/img/roopya_logo_original_colour.png";
import NavBarMobile from "../../assets/img/topNavSideDrawer.png";
// * Components
import AddUserModal from "../../pages/Users/AddUser";
import CustomDialog from "../Dialog/dialog";

import { NavLink } from "react-router-dom";
import { getCookie } from "../../utils/common";

import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getOrganizationDetails } from "../../api/services/Organization";
import { getProfileData } from "../../api/services/Profile";
import routes from "../../constants/Routes/routes";
import { changePassword } from "../../store/actions/changePassword";
import { updateAlertMsg } from "../../store/actions/commonAction";
import { logout } from "../../store/actions/logout";
import { resetUserAuthDetailsDispatcher, setOrganizationLogoDispatcher } from "../../store/dispatchers/authDispatcher";
import { LogoutApi } from "../../store/sagas/logoutSaga";
import { deleteAllCookies } from "../../utils/common";
import Text from "../ReusableComponents/Text";

const TopNav = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [addMenuIsOpen, setAddMenuIsOpen] = useState(null);
    const [open, setOpen] = useState(false);
    const [rightDrawer, setRightDrawer] = useState(false);
    const name = getCookie("fullName");
    const domainCookie = getCookie("domain");
    const token = useSelector((state) => state.auth.token);
    const history = useHistory();

    const [modalTitle, setModalTitle] = useState("");
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [details, setDetails] = useState({});
    const [errMsg, seterrMsg] = useState("");
    const dispatch = useDispatch();
    const organizationLogo = useSelector((state) => state.auth.organizationLogo);
    const employee = useSelector((state) => state.auth.employee);

    const logout = () => {
        return LogoutApi()
            .then((result) => {
                // console.log(result);
                dispatch(resetUserAuthDetailsDispatcher);
                deleteAllCookies();

                let baseUrl = `https://lenders.${process.env.REACT_APP_BASE_URL}`;
                if (location.hostname === "localhost") {
                    baseUrl = `http://${window.location.host}`;
                }
                setTimeout(() => {
                    window.location.replace(`${baseUrl}/#${routes.publicRoutes.SigninPage.getUrl()}`);
                }, 500);
                // let origin = window.location.origin.slice(0, 8);
                // if (window.location.host.includes("localhost")) {
                //   // window.location.replace('users/createpassword');
                //   window.location.replace(window.location.origin);
                //   // window.location.reload();
                //   // window.location.replace('users/createpassword');
                //   // history.push("/login");
                // } else {
                //   // window.location.replace('https://${process.env.REACT_APP_BASE_URL}/');
                //   // window.location.replace('users/createpassword');
                //   window.location.replace(
                //     `https://lenders.${process.env.REACT_APP_BASE_URL}/#/login`
                //   );
                //   // window.location.reload();
                // }
                setAnchorEl(null);
            })
            .catch((err) => {
                console.log({ err });
            });
    };

    const [profileImg, setProfileImg] = useState("");
    const [profileInfo, setProfileInfo] = useState("");
    const [profileData, setProfileData] = useState(null);

    const getOrganizationDataAPI = useQuery({
        queryKey: ["getOrganizationDetails"],
        queryFn: () => getOrganizationDetails().then(handleGetOrganizationSuccess),
        refetchOnWindowFocus: false,
        retry: false,
    });
    const handleGetOrganizationSuccess = (data) => {
        const logoPath = data.data?.organizationLogo?.documents;
        const logo = `${process.env.REACT_APP_DOCUMENTS_BASE_URL}${logoPath}`;
        dispatch(setOrganizationLogoDispatcher(logo));
        return data;
    };

    let link = document.getElementById("faviconDomain");
    link.href = getOrganizationDataAPI?.data?.data?.org_logo
        ? process.env.REACT_APP_DOCUMENTS_BASE_URL + getOrganizationDataAPI?.data?.data?.org_logo
        : "rlogo.jpeg";

    useQuery({
        queryKey: ["getProfileData"],
        queryFn: () =>
            getProfileData().then((data) => {
                // setDomainList(data.data.map((domain) => domain.domain_name));
                setProfileInfo(data?.data);
                setProfileImg(data?.data?.image);
                return data;
            }),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        setProfileData({ ...employee });
    }, [employee]);

    const moveAddTaskPage = () => {
        setAddMenuIsOpen(null);
        history.push(`/addtask`);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleAddMenuClose = () => {
        setAddMenuIsOpen(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = () => {
        setOpen(true);
        handleAddMenuClose(null);
    };

    const handleCloseUserModal = () => {
        setOpen(false);
    };

    const toggleDrawer = (open) => (event) => {
        setRightDrawer(!rightDrawer);
    };

    const drawerList = [
        { displayText: "Dashboard", onClick: `/${routeTo.DASHBOARD}` },
        { displayText: "Tasks", onClick: `/${routeTo.TASKLISTPAGE}` },
        { displayText: "Users", onClick: `/${routeTo.USERS}` },
        { displayText: "Pipelines", onClick: `/${routeTo.PIPELINES}` },
        { displayText: "Profile", onClick: `/${routeTo.USERPROFILE}` },
        { displayText: "Settings", onClick: `/${routeTo.SETTINGS}` },
        { displayText: "BusinessRules", onClick: `/${routeTo.BUSINESSRULES}` },
    ];

    const list = () => (
        <Box
            sx={{ width: "100%", marginTop: 40 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {drawerList.map((list, index) => (
                    <NavLink to={list.onClick} className={classes.navLink} key={index}>
                        <ListItem button key={list.displayText}>
                            <ListItemText primary={list.displayText} />
                        </ListItem>
                    </NavLink>
                ))}
            </List>
            <Divider />
        </Box>
    );

    const handleEditModalClose = () => {
        setEditModalOpen(false);
        setModalTitle("");
        seterrMsg({});
        setDetails({});
    };

    const handleChangePassword = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value });
    };
    const onSubmitFunction = () => {
        if (!details.password) {
            seterrMsg({
                textfieldErr: "password",
                messgae: "Please enter old password !!",
            });
        } else if (!details.new_password) {
            seterrMsg({
                textfieldErr: "new_password",
                messgae: "Please enter new password !!",
            });
        } else if (!details.confirm_password) {
            seterrMsg({
                textfieldErr: "confirm_password",
                messgae: "Please enter confirm_password !!",
            });
        } else if (details.new_password !== details.confirm_password) {
            seterrMsg({
                textfieldErr: "confirm_password",
                messgae: "Please enter correct password !!",
            });
        } else {
            return new Promise((resolve, reject) => {
                props.changePassword(details, resolve, reject);
            })
                .then((result) => {
                    props.updateAlertMsg({
                        message: (result && result.message) || "",
                        messageType: "success",
                    });
                    handleEditModalClose();
                })
                .catch((err) => {
                    const { response } = err;
                    const { data } = response;
                    props.updateAlertMsg({
                        message: data.message || "Something went wrong , please try again later",
                        messageType: "error",
                    });
                });
        }
    };

    const openDialog = (title) => {
        return (
            <CustomDialog open={editModalOpen} handleClose={() => handleEditModalClose()}>
                <DialogTitle
                    style={{
                        textAlign: "center",
                        borderBottom: "1px solid #cccc",
                    }}
                >
                    Change your password
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} mt={2}>
                            <Text fontSize={16} mb={1}>
                                Old password
                            </Text>
                            <TextField
                                id="password"
                                name="password"
                                placeholder="Enter old password"
                                onChange={handleChangePassword}
                                type="password"
                                // error={errMsg}
                                fullWidth
                                InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: false,
                                    required: true,
                                    className: classes.bootstrapFormLabel,
                                }}
                            />
                            {errMsg.textfieldErr == "password" && (
                                <FormHelperText error>{errMsg.messgae}</FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={12} mt={2}>
                            <Text fontSize={16} mb={1}>
                                New password
                            </Text>
                            <TextField
                                id="new_password"
                                name="new_password"
                                type="password"
                                placeholder="Enter new password"
                                onChange={handleChangePassword}
                                fullWidth
                                // error={errMsg}
                                InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: false,
                                    required: true,
                                    className: classes.bootstrapFormLabel,
                                }}
                            />
                            {errMsg.textfieldErr == "new_password" && (
                                <FormHelperText error>{errMsg.messgae}</FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={12} mt={2}>
                            <Text fontSize={16} mb={1}>
                                Confirm password
                            </Text>
                            <TextField
                                id="confirm_password"
                                name="confirm_password"
                                type="password"
                                placeholder="Enter confirm password"
                                // error={errMsg}
                                onChange={handleChangePassword}
                                fullWidth
                                InputProps={{
                                    disableUnderline: true,
                                    classes: {
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: false,
                                    required: true,
                                    className: classes.bootstrapFormLabel,
                                }}
                            />
                            {errMsg.textfieldErr == "confirm_password" && (
                                <FormHelperText error>{errMsg.messgae}</FormHelperText>
                            )}
                        </Grid>
                    </Grid>

                    <div className={classes.editModalButtonContainer}>
                        <Button
                            variant="contained"
                            className={classes.modalButtonStyle}
                            onClick={() => handleEditModalClose()}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" className={classes.modalButtonStyle} onClick={onSubmitFunction}>
                            Submit
                        </Button>
                    </div>
                </DialogContent>
            </CustomDialog>
        );
    };

    const handleLogoError = () => dispatch(setOrganizationLogoDispatcher(roopyaLogoNew));

    // useEffect(() => {
    //     console.log({ domainCookie, token });
    //     if (!domainCookie && !!token) {
    //         logout()
    //     }

    // }, [domainCookie, token])

    const handleOpenProfile = () => {
        history.push("/myProfile");
        setAnchorEl(null);
    };

    return (
        <>
            <CssBaseline />
            {open && <AddUserModal modalopen={open} handleClose={handleCloseUserModal} />}
            {openDialog(modalTitle)}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                style={{ zIndex: 9999999999 }}
                variant="menu"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleClose}
            >
                <MenuItem onClick={handleOpenProfile} style={{ display: "flex", gap: "0.5rem" }}>
                    <CgProfile />
                    <span>My Profile</span>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setEditModalOpen(true);
                        setAnchorEl(null);
                        setModalTitle("Change your password");
                    }}
                    style={{ display: "flex", gap: "0.5rem" }}
                >
                    <RiLockPasswordLine />
                    <span>Change Password</span>
                </MenuItem>
                <MenuItem onClick={logout} style={{ display: "flex", gap: "0.5rem" }}>
                    <AiOutlineLogout />
                    <span>Logout</span>
                </MenuItem>
            </Menu>
            <Menu
                id="simple-menu"
                anchorEl={addMenuIsOpen}
                keepMounted
                open={Boolean(addMenuIsOpen)}
                variant="menu"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={handleAddMenuClose}
            >
                <MenuItem onClick={handleOpen}>Add Member</MenuItem>
                <MenuItem onClick={moveAddTaskPage}>Add Task</MenuItem>
            </Menu>

            <AppBar
                position="fixed"
                color="inherit"
                // className={clsx(classes.appBar, classes.appbarBackground)}
                // className={`${classes.appBar} ${classes.appbarBackground}`}
            >
                <Toolbar className={classes.toolBar}>
                    <Box display="flex" alignItems="center" className={classes.compContainer}>
                        <div className={classes.leftSection}>
                            <NavLink to={`${routeTo.DASHBOARD}`} className={clsx(classes.navLink, classes.navLinkHome)}>
                                {/* <Button
                  className={clsx(classes.menuButton, classes.logoButton)}
                >
                  <Typography className={classes.taskManagementStyle}>
                    <img
                      // style={{ width: "100%", height: "2.2rem" }}
                      className={classes.clientLogo}
                      src={organizationLogo ? organizationLogo : roopyaLogoNew}
                      onError={handleLogoError}
                      alt="img"
                    />
                  </Typography>
                </Button> */}
                                <div className={style.clientLogoBox}>
                                    <img
                                        className={style.clientLogo}
                                        src={organizationLogo ? organizationLogo : roopyaLogoNew}
                                        onError={handleLogoError}
                                        alt="Client Logo"
                                    />
                                </div>
                            </NavLink>
                        </div>
                        <div
                            style={{
                                display: "none",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div className={classes.mobileViewRightSection}>
                                <img
                                    alt=""
                                    src={NavBarMobile}
                                    style={{
                                        height: 30,
                                        width: 30,
                                        cursor: "pointer",
                                        filter: "contrast(100) brightness(0)",
                                    }}
                                    onClick={toggleDrawer(true)}
                                />
                                <Drawer anchor="top" open={rightDrawer} onClose={toggleDrawer(false)}>
                                    {list()}
                                </Drawer>
                            </div>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon className={classes.searchIconStyle} />
                                </div>
                                <InputBase
                                    placeholder="Search here"
                                    className={classes.searchInputStyle}
                                    classes={{
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ "aria-label": "search" }}
                                />
                            </div>
                        </div>

                        <div className={classes.rightSection}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    margin: 10,
                                    alignItems: "center",
                                }}
                            >
                                <p
                                    style={{
                                        marginBottom: "0em",
                                        color: "#536878",
                                        fontFamily: "Red Hat Display",
                                        fontSize: "1rem",
                                    }}
                                >
                                    {profileData?.fullName}
                                </p>

                                <div>
                                    {console.log(
                                        "image temp log :",
                                        process.env.REACT_APP_DOCUMENTS_BASE_URL + profileData?.image
                                    )}
                                    <Avatar
                                        alt={name}
                                        src={
                                            profileImg
                                                ? process.env.REACT_APP_DOCUMENTS_BASE_URL + profileData?.image
                                                : "/static/images/avatar/1.jpg"
                                        }
                                        onClick={handleClick}
                                        style={{
                                            marginLeft: 10,
                                            height: 45,
                                            width: 45,
                                            color: profileImg ? "#0a2472" : "#fff",
                                            background: profileImg ? "#fff" : "#0a2472",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            logout,
            // logoutSuccess,
            updateAlertMsg,
            changePassword,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopNav));

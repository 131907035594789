import { call, put, takeLatest, all, fork} from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
// import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty} from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Update secret key API
function updateSecretKeyApi(params) {
  // console.log(params);
  // let details = { pipeline_id: params.pipeline_id };
  let id = params.secretkeyId;
  // console.log(id, details, "params id");
  return axios.request({
    method: "put",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.UPDATE_SECRETKEY}/${id}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    // data: details,
  });
}

// Update secret key action effect
export function* updateSecretKeyActionEffect(updateSecretKeyAction) {
  const { payload, resolve, reject } = updateSecretKeyAction;

  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(updateSecretKeyApi, payload);
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}
// Update secret key action watcher
export function* updateSecretKeyActionWatcher() {
  yield takeLatest(actions.UPDATE_SECRET_KEY, updateSecretKeyActionEffect);
}

export default function* rootSaga() {
  yield all([fork(updateSecretKeyActionWatcher)]);
}

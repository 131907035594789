import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  // contentWrapper: {
  //   [theme.breakpoints.up("sm")]: {
  //     width: "80%",
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     width: "100%",
  //   },
  // },
  // primaryFooterWrapper: {
  //   background: "#FFECCC",
  //   paddingTop: 45,
  //   paddingBottom: 45,
  // },
  // secondaryFooterWrapper: {
  //   paddingTop: 45,
  //   paddingBottom: 45,
  //   paddingLeft: "15px",
  //   paddingRight: "15px",
  //   borderTopColor: "#564312",
  //   borderTopWidth: 1,
  //   borderTopStyle: "solid",
  // },
  // paperMain: {
  //   padding: "21px 24px",
  //   margin: theme.spacing(4),
  //   minHeight: "100vh",
  // },
  // container11: {
  //   background: "#EEEEEE",
  //   minHeight: "100%",
  //   // minHeight: "100vh",
  //   margin: "0 auto",
  //   [theme.breakpoints.down("sm")]: {
  //     paddingLeft: "0px",
  //     paddingRight: "0px",
  //     background: "#fff",
  //   },
  // },

  // containerRight11: {
  //   background: "#FFFFFF",
  //   minHeight: "100vh !important",
  //   margin: "0 auto",
  //   marginLeft: 230,
  //   // paddingLeft: 48,
  //   width: "calc(100% - 230px)",
  //   transition: theme.transitions.create("width", {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  //   [theme.breakpoints.down("sm")]: {
  //     paddingLeft: "0px",
  //     paddingRight: "0px",
  //     marginLeft: 0,
  //     background: "#fff",
  //   },
  // },
  containerRightFull11: {
    // background: "#f4f5ff",
    background: "#f6faff",
    minHeight: "100vh !important",
    margin: "0 auto",
    marginLeft: 48,
    overflowX: "hidden",
    // width: "calc(100% - 48px)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
      marginLeft: 0,
      background: "#fff",
    },
  },
  mobileViewSideNav: {
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mainPaper: {
    backgroundColor: "transparent !important",
    /* paddingTop: "4em", */
    width: "98%",
    marginLeft: "1%",
    minHeight: "100vh",
  },
}));

export default styles;

import { alpha, makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },

  userGridPaper: {
    backgroundColor: "#EEEEEE",
    boxShadow: "none",
    marginTop: 10,
    marginLeft: 1,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#fff",
      marginTop: 45,
      marginLeft: 1,
    },
  },
  teamMembersPage: {
    color: "#172B4D",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    // padding: "0 20px",
    paddingTop: "50px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  userdBackground: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: "#fff",
    padding: "8px 24px",
    alignItems: "center",
    boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
    borderRadius: "8px",

    [theme.breakpoints.down("sm")]: {
      padding: "8px 0px 8px 8px",
    },
  },
  teamMembersContentContainer: {
    display: "flex",
    gap: "12px 8px",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    padding: "0",
  },
  // boxResponsive: {
  //   [theme.breakpoints.down("sm")]: {
  //     display: "block !important",
  //   }
  // },
  searchIconStyle: {
    color: "#333333",
    zIndex: 1,
  },
  myteamIconStyle: {
    color: "#0A2472",
    fontWeight: "bold",
    fontFamily: "Red Hat Display",
    fontSize: 16,
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      marginLeft: 0,
      paddingLeft: 3,
    },
  },
  userheaderOption: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      marginRight: 9,
    },
  },
  searchTaskStyle: {
    background: "#fff",
    borderRadius: 4,
    fontSize: 13,
  },
  adduserButtonstyle: {
    backgroundColor: "#0A2472 !important",
    color: "white",
    padding: "5px 20px",
    borderRadius: "4px",
    boxShadow: "none",

    "& > span": {
      "& > div": {
        color: "#fff",
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },

  teamListTextImage: {
    height: 28,
    width: 28,
    marginRight: 10,
    marginLeft: 15,
    marginTop: 4,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1px",
    },
  },
  showIcon: {
    border: "1px solid #cccccc",
    height: "28px",
    width: "30px",
    color: "rgb(122 122 122)",
    opacity: 0.7,
  },
  activeColor: {
    color: "#0A2472",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      padding: "10px",
      overflowY: "scroll",
      background: "#000000ad",
      overflowX: "hidden",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    height: "auto",
    width: "50%",
    borderRadius: 5,
    margin: "127px",
    position: "relative",
    outlineColor: "white",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      width: "100%",
      borderRadius: 5,
    },
  },
  textAreastyle: {
    border: "1px solid rgb(206, 212, 218)",
    borderRadius: 5,
  },
  buttonStyle: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginBottom: 10,
    marginRight: 6,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      paddingBottom: 10,
    },
  },

  fradGrid: {
    borderBottom: "2px solid #E1E1E1",
    padding: "0px 0px 0px 20px",
    height: 45,
    paddingTop: "1%",
    justifyContent: "flex-start",
    display: "flex",
  },

  headerStyle: {
    color: "#01488B",
    margin: "5px 0px",
    fontSize: "18px",
    fontWeight: "bold",
  },

  closeIconStyle: {
    position: "absolute",
    right: "6px",
    top: "5px",
    cursor: "pointer",
  },
  detailsText: {
    padding: "8px 0px 0px 12px",
  },
  memberDetails: {
    color: "#000000",
    font: "Red Hat Display",
    fontSize: "18px",
    fontWeight: "bold",
    marginLeft: "10px",
  },

  root: {
    maxWidth: "100% !important",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "row",
    alignItems: "center",
    // overflow: "hidden",
  },

  loginFormContainer: {
    // display: "flex",
    // flexDirection: "row",
    margin: "4px 1px",
    // marginLeft: "10px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: "4px 16px",
    },
  },

  bootstrapRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    height: "16px !important",
    fontSize: 16,
    // padding: " 23px ​0px 2px 20px",
    padding: "10px 12px",

    width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Red Hat Display",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },

  inputLabelStyle: {
    color: "#172B4D",
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 8,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    // padding: "13px 1px 7px 17px"
  },
  textField: {
    // marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    border: "1px solid rgb",
    // borderRadius: 5,
    width: 230,
    color: "black",
    fontWeight: 400,
    fontSize: 14,
    marginBottom: "3px",
    marginTop: "0px",
  },
  respReportingStyle: {
    minWidth: "100%",
    // border: "1px solid #ced4da",
    [theme.breakpoints.down("sm")]: {
      minWidth: "88%",
      marginTop: "10px",
      // border: "1px solid #ced4da",
    },
  },
  respHideIcon: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  selectInputHeight: {
    height: 38,
  },
  selectStyles: {
    height: 12,
  },
}));

export default styles;

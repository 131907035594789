import React from "react";
import { Route, Switch } from "react-router-dom";
import routes from "../constants/Routes/routes";

const PublicRoutes = () => {
    return (
        <Switch>
            {Object.values(routes.publicRoutes).map((each) => (
                <Route key={each.key} component={each.Component} exact path={each.path} />
            ))}
        </Switch>
    );
};

export default PublicRoutes;

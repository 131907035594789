import { call, put, takeLatest, all, fork, select } from "redux-saga/effects";
import * as actions from "../actions/types";
import axios from "../../utils/axios";
import _ from "lodash";
import * as urls from "../../utils/urls";
import { isEmpty, getCookie } from "../../utils/common";
import { toggleBackDrop } from "../actions/commonAction";

// Task comment API
function taskCommentApi(params) {
  // console.log(" taskCommentAPI", params);
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.TASK_COMMENTS}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    data: params,
  });
}

// Single Task commet effect
export function* taskCommentActionEffect(taskDetailsAction) {
  // console.log("taskDetailsAction", taskDetailsAction);
  const { payload, resolve, reject } = taskDetailsAction;
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(taskCommentApi, payload);
    yield put(toggleBackDrop(false));

    if (!isEmpty(data)) {
      if (resolve) resolve(data);
    } else {
      yield put(toggleBackDrop(false));
      reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    if (reject) reject(e);
  }
}

//Single Task COMMENT action watcher
export function* taskCommentActionWatcher() {
  yield takeLatest(actions.TASK_COMMENTS, taskCommentActionEffect);
}

export default function* rootSaga() {
  yield all([fork(taskCommentActionWatcher)]);
}
